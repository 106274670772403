import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import img1 from "../../assets/img/objet-design/img-1.jpg"
import img2 from "../../assets/img/objet-design/img-2.jpg"
import img3 from "../../assets/img/objet-design/img-3.jpg"
import img4 from "../../assets/img/objet-design/img-4.jpg"

const ObjetDesignPage = ({ data }) => {
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)

return(
  <Layout>
    <SEO title="Façade" />
   <div class="md:mx-40 md:my-5 mx-3">
   <div class="grid grid-cols-1 mt-8 gap-5">
     <h1 class="text-center text-2xl font-bold uppercase">UNE PETITE TOUCHE SOLID SURFACE !</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
       <div>  <img src={img1} alt="image 1" /> </div>
       <div>  <img src={img2} alt="image 2" /> </div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
    <div>
        <p class="font-bold">Des objets en résine minérale pratiques et déco …</p>
        <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
      <p class="mb-3">Vous avez l’habitude de voir le matériau Solid Surface dans nos cuisines et salle de bains (vasques, éviers, etc.) mais ne vous inquiétez pas la résine minérale n’oublie personne !!!</p>
      <p class="mb-3">Et oui, on vous le dit et le répète : <span class="font-bold">« La seule limite de création avec le Solid Surface est l’imagination… »</span></p>
      <p>Grâce à sa flexibilité et à son assemblage sans joint apparent, ce matériau, composé de deux tiers de minéraux naturels et d’un tiers de résine acrylique, offre d’infinies possibilités de création et une résistance exceptionnelle.</p> 
    </div>
    <div><img src={img3} alt="image 3" /></div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
        <div><img src={img4} alt="4" /></div>
        <div>
          <p class="mb-3">Alors pour ceux qui souhaiteraient rajouter une petite touche de ce fabuleux matériau dans leur déco ou pourquoi pas sur eux même, ne vous en privez pas !</p>

          <p class="mb-3">Voici notre sélection méli-mélo d’objet en résine minérale…</p>

          <p>Il y en a pour tous les goûts !</p>
        </div>
      </div>
      <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      <div class="gl gl33 mt-5"> <Gallery images={g} /></div>
   </div>
   </Layout>
)
}
export const query = graphql`
query DesignGallery {
  gallery:allFile (filter:{relativeDirectory: {eq: "img/objet-design/g"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default ObjetDesignPage